const Sort = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11066 7.39032C4.77383 7.05349 4.22773 7.05349 3.8909 7.39032C3.55407 7.72714 3.55407 8.27325 3.8909 8.61007L8.3909 13.1101C8.72773 13.4469 9.27383 13.4469 9.61066 13.1101L14.1107 8.61007C14.4475 8.27325 14.4475 7.72714 14.1107 7.39032C13.7738 7.05349 13.2277 7.05349 12.8909 7.39032L9.00078 11.2804L5.11066 7.39032Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Sort
